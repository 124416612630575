import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import {
    Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from 'reactstrap';
import isString from 'lodash/isString';
import FormInput from '../../components/FormInput';
import { getOAuthUrl } from '../../api/oauth/reapit';
import { getApiErrorMessages } from '../../helpers/apiMessages';
import ApiErrorAlert from '../../components/Alerts/ApiErrorAlert';
import { login, resetLoginError } from '../../redux/auth/actions';
import loginLogo from '../../assets/img/brand/viewber_logo.png';
import loginSide from '../../assets/img/brand/login-side.webp';
import CryptoJS from 'crypto-js';

const { REACT_APP_API_KEY, REACT_APP_ONE_TIME_PASSWORD_KEY } = process.env;

export class Login extends Component {
    constructor(props) {
        super(props);

        let urlParams = {};

        if (props.location) {
            urlParams = queryString.parse(props.location.search);
        }

        const password = urlParams?.otp
            ? this.decryptOTP(urlParams.otp)
            : ''

        const email = urlParams?.email ?? ''

        this.state = {
            email: email,
            password: password,
            signing_in_with_reapit: false,
            sign_in_with_reapit_error: urlParams.signInWithReapitFailed === '1'
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.handleSignInWithReapitClick = this.handleSignInWithReapitClick.bind(this);
    }

    decryptOTP(password) {
        const encryptStr = CryptoJS.enc.Base64.parse(password);
        const encryptData = JSON.parse(encryptStr.toString(CryptoJS.enc.Utf8));
        const iv = CryptoJS.enc.Base64.parse(encryptData.iv);

        let decrypted = CryptoJS.AES.decrypt(encryptData.value,  CryptoJS.enc.Base64.parse(REACT_APP_ONE_TIME_PASSWORD_KEY), {
            iv : iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return CryptoJS.enc.Utf8.stringify(decrypted);
    };

    handleLogin(evt) {
        evt.preventDefault();

        const { resetLoginError, errors, login } = this.props;
        const { email, password } = this.state;
        
        const data = {
            api_key: REACT_APP_API_KEY,
            email,
            password,
        };

        if (errors.length > 0) {
            resetLoginError();
        }

        login({ data });
    };

    updateInputValue(evt) {
        const { resetLoginError, errors } = this.props;

        if (errors.length > 0) {
            resetLoginError();
        }

        if (this.state.sign_in_with_reapit_error) {
            this.setState({ sign_in_with_reapit_error: false })
        }

        this.setState({
            [evt.target.name]: evt.target.value,
        });
    }

    handleSignInWithReapitClick() {
        this.setState({
            signing_in_with_reapit: true,
            sign_in_with_reapit_error: false
        });

        // The SignInReapit.js page will redirect back here
        // if the redux.state.auth.error !== null/undefined.
        // however because we use window.location.href = response.url
        // the redux state will be refreshed when we return
        // and redux.state.auth.error should be reset to null
        // so we do not need to use resetLoginError(), however
        // if this flow ever changes then we should call
        // resetLoginError() before redirecting to SignInReapit.js
        // so that the redux.state.auth.error is explicitly set
        // to null and SignInReapit.js can correctly perform
        // its actions.

        getOAuthUrl().then(response => {
            if (isString(response.url)) {
                window.location.href = response.url;
            } else {
                this.setState({
                    signing_in_with_reapit: false,
                    sign_in_with_reapit_error: true
                });
            }
        }).catch(err => {
            this.setState({
                signing_in_with_reapit: false,
                sign_in_with_reapit_error: true
            });
        });
    }

    render() {
        const {
            logged_in,
            logged_out,
            logging_in,
            must_complete_mfa
        } = this.props;

        const {
            signing_in_with_reapit,
            sign_in_with_reapit_error
        } = this.state;


        if (must_complete_mfa === true) {
            return <Redirect to="/2fa" />;
        }

        if (logged_in === true) {
            return <Redirect to="/" />;
        }


        let errors;

        if (sign_in_with_reapit_error) {
            errors = this.props.errors.concat(['Failed to sign in with Reapit. Please retry.']);
        } else {
            errors = this.props.errors;
        }

        const loginButtonIconClassName = typeof logging_in !== 'undefined' && logging_in === true
            ? 'fa fa-spinner fa-spin'
            : 'fa fa-sign-in';

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md='8'>
                            <Card className='p-2'
                                  style={{ borderRadius: '0.75rem' }}>
                                <CardBody className='d-flex flex-xl-row flex-column-reverse align-items-center'>
                                    <Col className='col-12 col-xl-6'>
                                        <img src={loginLogo}
                                             alt='Viewber logo'
                                             className='w-75' />
                                        <h3 className='pt-4 pb-3'>Sign In as a Client</h3>
                                        <p className='hover-blue font-weight-bold font-xs pb-2'>
                                            Welcome back! Please enter your details...
                                        </p>
                                        <ApiErrorAlert errors={errors} />
                                        <Form innerRef={'login-form'}>
                                            <InputGroup className='mb-3'>
                                                <FormInput className='border-dark'
                                                           type='email'
                                                           name='email'
                                                           placeholder='Enter your email'
                                                           value={this.state.email}
                                                           onChange={this.updateInputValue} />
                                            </InputGroup>
                                            <InputGroup className='mb-4'>
                                                <FormInput className='border-dark'
                                                           type='password'
                                                           name='password'
                                                           placeholder='Enter your password'
                                                           value={this.state.password}
                                                           onChange={this.updateInputValue} />
                                            </InputGroup>
                                            <Button type='submit'
                                                    color='auth-blue'
                                                    disabled={logging_in || signing_in_with_reapit}
                                                    onClick={this.handleLogin}
                                                    block>
                                                Login
                                            </Button>
                                            <Row className='mt-3'>
                                                <Col className='col-12 col-sm-6 pr-sm-1'>
                                                    <Button color='auth-light-blue'
                                                            tag='a'
                                                            href={`/password/reset`}
                                                            block>
                                                        Forgot password?
                                                    </Button>
                                                </Col>
                                                <Col className='col-12 col-sm-6 pl-sm-1 mt-3 mt-sm-0'>
                                                    <Button color='ghost'
                                                            tag='a'
                                                            href="https://viewber.co.uk/new-client"
                                                            block>
                                                        Sign up
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col className='col-12'>
                                                    <div className="text-center font-weight-bold font-xs border-bottom"
                                                        style={{ lineHeight: '0.1rem' }}>
                                                        <span className='text-muted bg-white px-4'>
                                                            or
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col className='col-12'>
                                                    <Button color='ghost'
                                                            disabled={logging_in || signing_in_with_reapit}
                                                            loading={signing_in_with_reapit}
                                                            onClick={this.handleSignInWithReapitClick}
                                                            fullWidth
                                                            block>
                                                        Sign in with Reapit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col className='col-12 col-xl-6 d-none d-xl-block h-100'>
                                        <img src={loginSide}
                                             alt='Viewber Sign In'
                                             className='img-fluid' />
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        error,
        logged_out,
        logging_in,
        token,
        must_complete_mfa
    } = state.auth;

    const errors = getApiErrorMessages(error);

    return {
        errors,
        must_complete_mfa,
        logged_in: token ? true : false,
        logged_out: logged_out === true,
        logging_in: logging_in === true,
    };
};

const mapDispatchToProps = {
    login,
    resetLoginError,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
